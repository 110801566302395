<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="2" lg="1" xl="2" class="mx-0 px-0 mr-md-2 py-lg-0">
						<alternativeAutocompleteOne
                            :items="years"
                            :valueInput="selectedYear"
                           
                            @valueChange="(v) => {selectedYear = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Año'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                    <v-col cols="12" md="2" lg="2" xl="2" class="mx-0 px-0 mr-md-2 py-lg-0">
						<alternativeAutocompleteOne
                            :items="months"
                            :valueInput="selectedMonth"
                           
                            @valueChange="(v) => {selectedMonth = v}"
                            @onChange="(v) => {index()}"
                           
                            :label="'Mes'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
					</v-col>
                   
                    
					<v-spacer></v-spacer>
					<!-- <v-col  cols="12" md="5" class="mx-0 py-lg-0 ">
                        <v-row justify="end">
                            <iconButton :props="exportBtnData"></iconButton>
                        </v-row>
					</v-col> -->
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<v-card class="index-card ">
                            <v-card-text  class="text-center">
                                <v-col cols="12" lg="8" offset-lg="2">
                                    <div class="summary-title mb-7">
                                        <span>Estado de resultados de {{businessName}} para {{ut.getFullMonths()[parseInt(selectedMonth) - 1]}} de {{selectedYear}}</span>
                                    </div>
                                    <div v-if="loading">
                                        <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        :size="50"
                                        ></v-progress-circular>
                                    </div>
                                    <div v-if="!loading">

                                        <v-col class="summary-concepts-header">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Concepto</span>
                                                <span class="summary-concept">Monto</span>
                                            </v-row>
                                        </v-col>
                                        <!-- <v-divider></v-divider> -->
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Ventas</span>
                                                <span class="summary-price">{{ut.currencyFormat(sales, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Cuentas por cobrar</span>
                                                <span class="summary-price">{{ut.currencyFormat(receivableAccount, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Otros ingresos</span>
                                                <span class="summary-price">{{ut.currencyFormat(otherIncomes, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Egresos</span>
                                                <span class="summary-price">{{ut.currencyFormat(expenses, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Cuentas por pagar</span>
                                                <span class="summary-price">{{ut.currencyFormat(payableAccount, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Otros gastos</span>
                                                <span class="summary-price">{{ut.currencyFormat(otherExpenses, "")}}</span>
                                            </v-row>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <div class="mt-1"></div>
                                        <v-divider></v-divider>
                                        <v-col class="my-2">
                                            <v-row class="mx-1" justify="space-between">
                                                <span class="summary-concept">Margen de contribución</span>
                                                <span :class="`summary-total ${total >= 0 ? 'green--text' : 'red--text'}`">{{ut.currencyFormat(total, "")}}</span>
                                            </v-row>
                                        </v-col>
                                    </div>
                                    
                                </v-col>
                            </v-card-text>
                        </v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

     
	</div>
</template>
<script>
import PageStateManager from '../../helpers/PageStateManager';
import Utils from '../../helpers/Utils';
export default {
    data(){
		return {
            loading: true,
            ut: Utils,
            selectedYear: PageStateManager.getState(this.$router.history.current.path, 'selectedYear')??'',
            years: [
                
            ],
            selectedMonth: PageStateManager.getState(this.$router.history.current.path, 'selectedMonth')??'',
            months: [
                
            ],
            businessName: "",
			serverErrors: {},

            sales: 0,
            receivableAccount: 0,
            otherIncomes: 0,
            expenses: 0,
            payableAccount: 0,
            otherExpenses: 0,
            total: 0,
            
			
			
			
            exportBtnData: {
                hasTooltip:true,
                tooltip: 'Exportar excel',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-microsoft-excel",
                iconClass: "",
				block: false,
                loading: false,
                disabled: false,
                click: ()=>{this.export();}
            },

            





			
		}
	},

  
    watch: {
        /**
         * save change
         */
        [`selectedYear`]: function () {
            // console.log(this.selectedYear, "changed")
            PageStateManager.setState(this.$router.history.current.path, 'selectedYear', this.selectedYear);
        },
        [`selectedMonth`]: function () {
            // console.log(this.selectedMonth, "changed")
            PageStateManager.setState(this.$router.history.current.path, 'selectedMonth', this.selectedMonth);
        },
       
       
    },
    

	mounted(){
        this.getYears();
        this.getMonths();
        this.loadBusinessName();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.index();
	},

    methods: {
        loadBusinessName(){
            const found = this.$store.state.userData.business.find((itm) => itm.slug == this.$store.state.business);
            this.businessName = found.name;
           
        },
        onResize () {
            
            if(window.innerWidth <= 960){
                this.exportBtnData.block = true;
                
            }
            else{
                
                this.exportBtnData.block = false;
            }
        },
        method_handler(object){
            // console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
       
		index() {
            // // console.log(this.$router)
            if(this.selectedYear == '' || this.selectedYear == null || this.selectedYear == undefined)
                return;
            if(this.selectedMonth == '' || this.selectedMonth == null || this.selectedMonth == undefined)
                return;
                
            this.loading = true;

            this.sales = 0;
            this.receivableAccount = 0;
            this.otherIncomes = 0;
            this.expenses = 0;
            this.payableAccount = 0;
            this.otherExpenses = 0;
            this.total = 0;
           
            
			//call api
			this.$api.report.reportIncomeStatement(this.$store.state.business, this.selectedYear, this.selectedMonth)
				.then((resp) =>{

                    this.sales = resp.data.sales??0;
                    this.receivableAccount = resp.data.receivable_account??0;
                    this.otherIncomes = resp.data.other_incomes??0;
                    this.expenses = resp.data.expenses??0;
                    this.payableAccount = resp.data.payable_account??0;
                    this.otherExpenses = resp.data.other_expenses??0;

                    this.total = (this.sales??0) + (this.receivableAccount??0) + (this.otherIncomes??0)
                        - (this.expenses??0) - (this.payableAccount??0) - (this.otherExpenses);


					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
                    this.loading = false;
				})
			;
           
		},

        
        getYears()
        {
            this.years = [];
            let now = new Date();
            now.setHours(0,0,0,0)
            const year = now.getFullYear();
            if(this.selectedYear == "")
                this.selectedYear = ""+year;
            for (let i = 2022; i <= year; i++) {
                this.years.push({text: ""+i, value: ""+i});
            }
        },

        getMonths()
        {
            this.months = Utils.getFullMonths().map((item, index) => {
                index = index + 1;
                return {text: item, value: index >= 10 ? index.toString() : '0'+index};
            });
            let now = new Date();
            now.setHours(0,0,0,0);
            if(this.selectedMonth == ""){
                const mt = now.getMonth() + 1;
                this.selectedMonth = mt >= 10 ? mt.toString() : '0'+mt;
            }



        },
        

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.payroll.exportAnnualBudget(this.selectedYear)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `annual_budget_${this.selectedYear}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },


       

    }
}
</script>


<style lang="scss">
    .table-line-height{
        line-height: 1.2rem !important;
    }

    .summary{
        &-title{
            padding: 20px 20px;
            background-color: var(--v-primary-base);
            font-weight: bold;
            font-size: 1rem;
            color: #515050;
        }

        &-concepts-header{
            border-bottom: black solid 2px;
        }


        &-concept{
            color: #000;
            font-weight: bold;
            font-size: .8rem;
        }
        &-price{
            color: unset;
            font-weight: 500;
            font-size: .8rem;
        }
        &-total{
            font-weight: bold;
            font-size: .9rem;
        }
    }
</style>