<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
                <v-row>
                    <v-col class="py-2">
                        <datePickerForm
                            label="Fecha"
                            :valueInput="values.date"
                            :rule="rules.required"
                            @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                        ></datePickerForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                        ></textFieldForm>
                    </v-col>
                </v-row>

            

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Cuenta"
                            :loading="loadingConcepts"
                            :valueInput="values.concept_id"
                            :rule="rules.required"
                            :items="serverItems.concepts"
                            :errorMessages="serverErrors.concept_id"
                            @valueChange="(v) => {values.concept_id = v; delete serverErrors.concept_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Sub Sub Cuenta"
                            :loading="loadingDeposits"
                            :valueInput="values.target_account_id"
                            :rule="rules.required"
                            :items="serverItems.deposits"
                            :errorMessages="serverErrors.target_account_id"
                            @valueChange="(v) => {values.target_account_id = v; delete serverErrors.target_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Forma de pago"
                            :loading="loadingMethods"
                            :valueInput="values.payment_method_id"
                            :rule="rules.required"
                            :items="serverItems.methods"
                            :errorMessages="serverErrors.payment_method_id"
                            @valueChange="(v) => {values.payment_method_id = v; delete serverErrors.payment_method_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col>
                        <textFieldForm 
                            @valueChange="(v) => {values.subtotal = v; total(); delete serverErrors.subtotal}" 
                            :valueInput="values.subtotal" 
                            :rule="[...rules.amount]" 
                            label="Subtotal"
                            :errorMessages="serverErrors.subtotal"
                            :prefix="values.subtotal === '' ? '' : '$'"
                        ></textFieldForm>
                    </v-col>

                    <v-col>
                        <textFieldForm 
                            @valueChange="(v) => {values.taxes = v; total(); delete serverErrors.taxes}" 
                            :valueInput="values.taxes" 
                            :rule="[...rules.amount]" 
                            label="Impuestos"
                            :errorMessages="serverErrors.taxes"
                            :prefix="values.taxes === '' ? '' : '$'"
                        ></textFieldForm>
                    </v-col>


                </v-row>

                <v-row>
                    <v-col>
                        <textFieldForm 
                            @valueChange="(v) => {values.total = v; delete serverErrors.total}" 
                            :valueInput="values.total" 
                            :rule="[...rules.amount]" 
                            label="Total"
                            :errorMessages="serverErrors.total"
                            :prefix="values.total === '' ? '' : '$'"
                            :disabled="true"
                        ></textFieldForm>
                    </v-col>

                    <v-col>
                        <autocompleteOneForm
                            label="Divisa"
                            
                            :valueInput="values.currency"
                            :rule="rules.required"
                            :items="items.currencies"
                            :errorMessages="serverErrors.currency"
                            @valueChange="(v) => {values.currency = v; delete serverErrors.currency}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Cuenta bancaria"
                            :loading="loadingBankAccounts"
                            :valueInput="values.bank_account_id"
                            :rule="rules.required"
                            :items="serverItems.bankAccounts"
                            :errorMessages="serverErrors.bank_account_id"
                            @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <autocompleteOneForm
                            label="Referencias"
                            :loading="loadingReferences"
                            :valueInput="values.reference_id"
                            :rule="[]"
                            :items="serverItems.references"
                            :errorMessages="serverErrors.reference_id"
                            @valueChange="(v) => {values.reference_id = v; delete serverErrors.reference_id}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col>
                        <textFieldForm 
                            @valueChange="(v) => {values.commentary = v; delete serverErrors.commentary}" 
                            :valueInput="values.commentary" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.commentary"
                        ></textFieldForm>
                    </v-col>
                </v-row>


                 <v-row>
                    <v-col class="py-2">
                        <fileInputForm
                            
                            label="Adjunto"
                            :multiple="false"
                            :valueInput="values.vaucher_file"
                            :url="values.vaucher"
                            :rule="[]"
                            @valueChange="(v) => {values.vaucher_file = v; delete serverErrors.vaucher_file}"
                            :errorMessages="serverErrors.vaucher_file"
                            v-on:update:url="values.vaucher = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row>





                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingBankAccounts: false,
            loadingConcepts: false,
            loadingDeposits: false,
            loadingMethods: false,
            loadingReferences: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
              
				
            },

            items: {
                currencies: [
                    {text: "MXN", value: "MXN"},
                    {text: "USD", value: "USD"}
                ],

            },
            serverItems: {
                concepts: [],
                deposits: [],
                methods: [],
                bankAccounts: [],
                references: [],
            }
            

          
        }
    },
   
    mounted(){
        this.getConcepts();
        this.getDeposits();
        this.getMethods();
        this.getBankAccounts();
        this.getReferences();
    },
    methods: {
        total() {
            this.values.total = Number(this.values.subtotal) + Number(this.values.taxes);
        },
        getConcepts()
        {
            this.loadingConcepts = true;
            this.$api.concept.getSelectConcepts(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.concepts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingConcepts = false;
                })
            ;
        },

        getDeposits()
        {
            this.loadingDeposits = true;
            this.$api.depositWithdraw.getDepositsSelect(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.deposits = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingDeposits = false;
                })
            ;
        },

        getMethods()
        {
            this.loadingMethods = true;
            this.$api.paymentMethod.getSelect()
                .then((resp) => {
                    this.serverItems.methods = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingMethods = false;
                })
            ;
        },

        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bank.accountGetSelect(this.$store.state.business)
                .then((resp) => {
                    //console.log(resp.data)
                    resp.data.forEach(element => {
                        this.serverItems.bankAccounts.push({
                            text: element.text,
                            value: element.value,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },

         getReferences()
        {
            this.loadingReferences = true;
            this.$api.reference.getSelectReferences(this.$store.state.business)
                .then((resp) => {
                    this.serverItems.references = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingReferences = false;
                })
            ;
        },
       
    }
}
</script>

<style>

</style>