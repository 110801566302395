<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles del ingreso
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
            
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../components/DetailsItem.vue';
import Utils from "../../helpers/Utils";
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            slug: this.$route.params.slug,
            loading: false,
           
            items: [
               
                
            ],
            
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Deposits",	
                click: () => {}
            },
            
         
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.deposit.get(this.$store.state.business, this.slug)
			.then((response) => {
                console.log(response);
                // this.items.forEach((y) => {
                //     y.valor = response.data[y.index]
                // });
               
                this.items= [
                    { concepto: 'Folio', index: 'folio', valor: response.data.folio },
                    { concepto: 'Fecha', index: 'date', valor: Utils.globalDateFormat(response.data.date) },
                    { concepto: 'Nombre', index: 'name', valor: response.data.name },
                    { concepto: 'Sub Sub Cuenta', index: 'target_account.name', valor: response.data.target_account?.name??''},
                    { concepto: 'Referencia', index: 'reference.name', valor: response.data.reference?.name??'' },
                    { concepto: 'Cuenta', index: 'concept.name', valor: response.data.concept?.name??'' },
                    { concepto: 'Cuenta de banco', index: 'bank_account.name', valor: response.data.bank_account?.name??'' },
                    { concepto: 'Método de pago', index: 'payment_method.name', valor: response.data.payment_method?.name??'' },
                    { concepto: 'Total', index: 'total', valor: Utils.currencyFormat(response.data.total, response.data.currency) },
                    { concepto: 'Subtotal', index: 'subtotal', valor: Utils.currencyFormat(response.data.subtotal, response.data.currency) },
                    { concepto: 'Iva', index: 'taxes', valor: Utils.currencyFormat(response.data.taxes, response.data.currency) },
                    { concepto: 'Comentario', index: 'commentary', valor: response.data.commentary },
                ];

                if(response.data.vaucher != '' && response.data.vaucher != null){
                    this.items.push(
                        {concepto: 'Adjunto', index: 'vaucher', valor: response.data.vaucher_url, isFile:true}
                    );
                }

               
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 